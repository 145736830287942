.block-document {
    padding: 0 !important;

    .doc-title {
        margin-left: 1rem;
    }

    .deco-border {
        display: inline-flex;
        align-items: center;
        color: $primary;

        .deco-content {
            /* Diseño total de 36px x 36px */
            padding-block-start: 7px;
            padding-block-end: 5px;
            padding-inline: 11.5px;
            margin-right: 1rem;
        }
    }
}
