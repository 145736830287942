.block-authorities {

    .authority {

        &:nth-child(even) {
            background-color: $grey-lighter;
        }

        .container {
            padding-block: $gap * 2;
            @include touch {
                margin-inline: $gap / 2;
            }
        }

        .image {
            border-radius: 10px;
            overflow: hidden;

            img {
                display: block;
            }
        }

        .title {
            color: $blue;
        }

        .subtitle {
            color: $blue-light;
            font-weight: 600;
        }

        [class*="block-"] {
            margin-top: $gap / 2;
            margin-bottom: 0;
            padding-block: 0 !important;
            margin-inline: 0;
            max-width: none;

            @include touch {
                margin-inline: 0;
            }
        }

        [class*="block-"]:first-child {
            margin-top: 0;
        }

        .block-paragraph {
            ul:first-child {
                margin-top: 0;
            }
        }

        &.hierarchy-1 {

            .block {
                @include mobile {
                    padding-top: 1.5rem;
                }

                .title {
                    @include tablet {
                        padding-top: $gap;
                    }
                }
            } 
        }

        &.hierarchy-2 {

            .block-paragraph, .block-column {
                @extend .content.is-normal;

            }
            .container {
                max-width: 888px;
            }
        }

    }

    .block-contact {
        .columns {
            margin: 0;
        }
    }
}
