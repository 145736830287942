.product #is-header-hero .hero-fullheight,
.service #is-header-hero .hero-fullheight {

    &.title {
        font-size: 2rem;
        @include tablet {
            font-size: 4rem;
        }
        @include widescreen {
            font-size: 6rem;
        }
        &.decoration {
            &::after {
                top: 3rem;
                @include tablet {
                    top: 5rem;
                }
                @include widescreen {
                    top: 8rem;
                }
            }
        }
    }
}

#is-header-hero {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    .hero-body {
        z-index: 1;
        
        .title {
            word-break: normal;
            line-height: inherit;
            font-size: 2rem;
            @include desktop {
                font-size: 3rem;
            }
        }

        .decoration {
            &::after {
                bottom: 0;
                top: 3rem;
                width: 2rem;
                @include desktop {
                    top: 3.5rem;
                }
            }
            @media (hover: hover) {
                &:hover::after {
                    width: unquote("min(100%, 300px)");
                }
            }
        }

        .hero-fullheight.decoration {
            &::after {
                top: 3rem;
                @include desktop {
                    top: 4rem;
                }
            }
        }
    }

    video {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
    }
}