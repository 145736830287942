#navbar {

    .navbar-item,
    .navbar-link {
        font-weight: normal;
        font-size: 1rem;
        min-height: 40px;
    }

    .navbar-link {
        padding: 0 0 0 0.75rem;

    }

    .level_1 {

        @include desktop {
            height: 100%;
        }

        > .navbar-link {
            @include touch {
                border-bottom: .5px solid $grey-lighter;
            }

            @include desktop {
                padding: 0.5rem 0.75rem;
            }

            &:last-child {
                border-bottom: none;
            }

            a {
                color: $white;
            }
        }
    }

    .navbar-dropdown {
        .navbar-item {
            border-radius: 0;
            display: block;
            padding: 0.5rem 0.75rem;

            &.has-dropdown {
                padding: 0;
            }

            @include desktop {
                color: $navbar-dropdown-item-color;
            }
        }

        &.navbar-dropdown-first {

            .navbar-item,
            .navbar-link {
                @include touch {
                    color: $white;
                }
            }
        }

        .navbar-dropdown-second {
            @include touch {
                background-color: $primary;
            }

            .navbar-item,
            .navbar-link {
                font-weight: normal;

                @include touch {
                    color: $white;
                }
            }
        }

        .navbar-dropdown-third {
            .navbar-item a,
            .navbar-link a {
                color: $primary !important;
            }
        }
    }
}

.has-navbar-transparent {
    #navbar {
        .navbar-item,
        .navbar-link {
            color: $white;

            a {
                color: $white;
            }
        }
    }
}

.is-context-menu,
.has-navbar-white {
    #navbar {
        .navbar-item,
        .navbar-link {
            color: $grey-black;

            a {
                color: $grey-black;
            }
        }

        .navbar-dropdown {
            .navbar-item,
            .navbar-link {

                @include desktop {
                    color: $navbar-dropdown-item-color;
                }

                a {
                    color: $navbar-dropdown-item-color;
                }
            }
        }
    }
}