.product .sharing,
.service .sharing {
    .buttons .button:hover {
        background: $black;
        color: $white;
    }
}

.sharing {
    $button-hover-color: $blue-light;
    $button-text-hover-color: $white;
    position: relative;
    column-gap: 0.5rem;

    .buttons {
        border-radius: 3px;

        .button {
            color: $grey-black;
            background-color: $grey-lighter;
            padding: 1.25rem;
            margin: 0;
            border: transparent;

            &:hover {
                background: $button-hover-color;
                color: $button-text-hover-color;
            }
        }
    }
}
