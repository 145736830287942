.block-products {
    position: relative;
    z-index: 2;

    .slider-content {
        margin: 0 auto;
        width: 100%;
        position: relative;

        .glide__slides {
            height: 400px;

            @include tablet {
                min-height: 810px;
            }

            @include desktop {
                min-height: 910px;
                @media only screen and (max-height: 900px) {
                    min-height: 700px;
                }
            }

            .glide__slide {
                display: flex;
                position: relative;

                .content-image {
                    $space-x: 1.5rem;
                    box-sizing: border-box;
                    display: flex;
                    flex-flow: column;
                    position: absolute;
                    z-index: 2;
                    left: $space-x;
                    right: $space-x;
                    bottom: 0;
                    height: 406px;

                    @include tablet {
                        height: 810px;
                    }

                    @include desktop {
                        height: 910px;
                    }

                    &--top {
                        justify-content: flex-start;
                        top: 20%;
                    }

                    .image-fullheight {
                        display: inline;

                        img {
                            height: 100%;
                            object-fit: contain;

                            @include tablet {
                                object-fit: cover;
                            }
                        }
                    }
                }

                picture {
                    height: 100%;
                    width: 100%;
                    position: absolute;

                    img {
                        height: 100%;
                    }
                }

                .container {
                    align-self: center;

                    @include mobile {
                        margin: 0 20px;
                        align-self: end;

                        & .box {
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }
}