.profile {
  background: url(../img/bg-profile.svg) no-repeat 4% top;
  background-size: contain;

  .profile-inner {
    min-height: 400px;
    padding: $gap * 5 0;

    [class*="block-"] {
      margin-bottom: 0;
    }

    .image {
      img {
        width: inherit;
      }
    }
  }

  .history {
    li {
      align-items: center;
      display: flex;
      margin-bottom: $gap * 2;

      > div {
        min-width: $gap * 12;

        @include tablet {
          min-width: $gap * 13;
        }

        &:first-child {
          border-right: 1px solid $grey-black;
          padding-right: $gap * 2;
          margin-right: $gap * 2;
        }

        h4 {
          font-family: $family-primary;
          font-weight: bold;
          text-transform: uppercase;
        }

        span {
          display: block;
          font-size: $gap;
          font-weight: $subtitle-weight;
          text-transform: uppercase;
        }

        a {
          color: $green;
        }
      }
    }
  }
}
.profile-item {
  .card {
      .card-image figure {
          margin-left: $card-content-padding;
          margin-right: $card-content-padding;
          &.image {
              &.is-3by4 {
                  padding-top: 100% !important;
              }
          }
          img {
              border-radius: $card-radius;
          }
      }
      .card-content {
          .buttons {
              .button {
                  background-color: inherit;
                  &:hover {
                      background-color: $blue-light;
                      color: $white;
                  }
              }
          }
      }
  }

  /* .overflow-y-scroll {
      overflow-y: scroll;
  } */
}

.bg-cebra {
  .profile-item:nth-child(odd) {
      background-color: hsl(0, 0%, 96%);
      .card {
          background-color: hsl(0, 0%, 96%);
      }
  }
}
