#navbar {

    .navbar-item.is-hoverable-menu:hover,
    .navbar-item.is-hoverable-menu:focus {
        .navbar-dropdown {
            display: block;
            opacity: 1;
            pointer-events: auto;
            transform: translateY(0);
        }
    }

    .has-dropdown {
        > .navbar-dropdown {
            @include touch {
                opacity: 0;
                overflow: hidden;
                /* transition: max-height $duration-0 ease-in; */
                max-height: 0;
            }

            .navbar-dropdown {
                opacity: 0;
                overflow: hidden;
                /* transition: max-height $duration-0 ease-in; */
                max-height: 0;
            }
        }

        &.hover-dropdown {
            > .navbar-dropdown {
                opacity: 1;
                /* transition: max-height $duration-0 ease-in; */
                max-height: 1000px;
            }
        }
    }

    .navbar-dropdown {
        padding: 0;
        box-shadow: none;

        &.navbar-dropdown-first {
            background-color: $primary-dark;

            @include desktop {
                border-radius: 0 0 6px 6px;
            }

            &:before {
                @include desktop {
                    content: "";
                    position: absolute;
                    left: 30px;
                    top: -6px;
                    width: 16px;
                    height: 16px;
                    background: $primary-dark;
                    border-radius: 4px;
                    @include transform(rotate, $rotate-45, $duration-100, $default);
                }
            }

            &:hover {
                &:after {
                    @include desktop {
                        background-color: transparent;
                        content: "";
                        position: absolute;
                        left: -50px;
                        top: 0;
                        right: -50px;
                        height: 100vh;
                        z-index: -1;
                    }
                }
            }
        }

        .navbar-dropdown {
            background-color: $primary;

            @include desktop {
                position: inherit;
            }

            .navbar-dropdown {
                background-color: $white;
                position: relative;

                .navbar-item {
                    color: $primary;
                }
            }
        }

        .has-dropdown {
            @include desktop {
                min-width: 230px;
            }
        }
    }
}