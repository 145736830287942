.breadcrumb {
    $link-color: $breadcrumb-item-color;
    $link-active-color: $breadcrumb-item-active-color;
    $separator-color: $breadcrumb-item-separator-color;
    align-items: center;
    justify-content: space-between;
    row-gap: 2rem;
    position: relative;
    z-index: 2;

    @include touch {
        padding: 0 1.5rem;
    }

    ul {
        li {
            &:before {
                color: $separator-color;
                content: "|";
            }

            &:first-child {
                &:before {
                    display: none;
                }
            }
            a {
                color: $link-color;
            }

            &.is-active {
                a {
                    color: $link-active-color;
                }
            }

        }
    }
}

.results {
    .breadcrumb {
        justify-content: flex-end;
    }
}
