.responsive-object {
  padding-bottom: 0 !important;
  position: relative;
  height: 25rem;
  width: 100%;

  object,
  embed,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.block-embed,
.block-video {
  max-height: 60rem;
}

.block-embed {
  padding-block: 3rem;
}

.block-video {

  iframe {
    display: block;
    width: 100%;
  }
}


