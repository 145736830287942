#finder {
    align-items: center;
    background: $white;
    transition: ease $duration-300;
    right: 0;
    width: 100%;
    z-index: 1;

    @include desktop {
        display: none;
        opacity: 0;
        position: fixed;
        width: 50%;
    }

    &.is-active {
        display: flex;
        opacity: 1;
        min-height: 4.25rem;

        @include fullhd {
            min-height: 5.25rem;
        }

        .container {
            height: 100%;
            align-items: center;
        }

        &:before {
            @include desktop {
                background-color: $white;
                content: "";
                position: absolute;
                left: -50%;
                transition: ease $duration-300;
                min-height: 4.25rem;
                width: 50%;
            }

            @include fullhd {
                min-height: 5.25rem;
            }
        }
    }

    form {
        width: 100%;
        @include desktop {
            width: 70%;
        }

        .icon {
            color: $grey-black;
            @include desktop {
                width: 2rem;
            }
        }

        input {
            background: transparent;
            border: none;
            border-radius: 0;
            box-shadow: none;
            color: $grey-black;

            @include touch {
                border-bottom: .5px solid $grey-lighter;
            }

            &::placeholder {
                color: $grey-black;
            }
        }
    }
}
