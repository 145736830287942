.block-direct_access {
    .menu-list {
        li {
            a {
                align-items: center;
                font-size: $gap;
                display: flex;
                padding: $gap / 2 0;

                &:hover {
                    background: transparent;

                    i {
                        @include transform(rotate, -$rotate-45, $duration-0, $default);
                    }
                }

                span {
                    border-bottom: 1px solid $grey-black;
                    color: $blue-light;
                }

                i {
                    transition: all $duration-300;
                    margin-left: 5px;
                    height: $gap;
                    width: $gap;
                }
            }
        }
    }
}
