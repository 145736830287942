.block-column {
  @extend .content;
  @extend .is-medium;
  columns: auto 1;
  align-items: center;

  a {
    @extend .short-deco;

    &:hover {
      color: $blue;
    }
  }

  &.block-column-2 {
    @include tablet {
      columns: auto 2;
    }
  }

  &.block-column-3 {
    @include tablet {
      columns: auto 2;
    }

    @include widescreen {
      columns: auto 3;
    }
  }

  &.block-column-4 {
    @include tablet {
      columns: auto 2;
    }

    @include widescreen {
      columns: auto 3;
    }

    @include fullhd {
      columns: auto 4;
    }
  }
}
