.new-teaser {
    display: flex;
    flex-direction: column;
    height: 100%;

    &.no-image {
        background: rgba($grey-light, 0.3);

        .new-teaser-image {
            .image {
                height: $gap * 5;
            }
        }

        .columns {
            display: block;

            h4.title {
                font-size: 2rem;
            }

            .is-one-fifth {
                width: 100%;

                .is-right {
                    justify-content: left;
                }
            }
        }
    }

    .new-teaser-image {
        margin-bottom: 1rem;
        position: relative;

        .image {
            margin: 0;
            height: 17.4375rem;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        .reference {
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: $primary;
            text-transform: uppercase;
            color: $white;
            padding: 0.3rem 2rem;
            font-weight: bolder;
            font-size: 1rem;
        }
    }

    .new-teaser-content {
        height: 100%;

        .container {
            max-width: 100%;

            h4 {
                margin-bottom: $gap;
                font-size: 1.15em;

                a {
                    color: $grey-black;
                    font-weight: bold;
                }
            }

            time {
                color: $grey-black;
                display: block;
                font-size: 0.85rem;
            }
        }
    }

    .description {
        .column {
            padding-top: 0;
        }
    }

    .button {
        border-color: $grey-black;
        border-radius: 6px;
        text-transform: uppercase;
    }
}
