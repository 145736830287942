.block-clients {

        @include mobile {
            max-width: none !important;
            padding-left: $gap;
            padding-right: $gap;
            width: 100%;
        }

    .client {
        margin-bottom: $gap;
        position: relative;

        .client-image {
            border-radius: 10px;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            height: 100%;
            position: relative;
            z-index: 2;

            img {
                display: block;
                filter: grayscale(100%);
                transition: all 0.5s ease;
            }
        }

        .client-description {
            background: $blue;
            color: $white;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            opacity: 0;
            transition: all 0.5s ease;
            padding: 2rem 0.75rem 0.75rem;
            position: absolute;
            left: 0.75rem;
            right: 0.75rem;
            bottom: $gap;
            z-index: 1;

            .client-title {
                font-weight: 600;
            }
        }

        @include desktop {
            &:hover {
                z-index: 3;

                .client-image {

                    img {
                        filter: grayscale(0);
                        transition: all 0.5s ease;
                    }
                }

                .client-description {
                    opacity: 1;
                    transform: translateY(95%);
                }
            }
        }
    }

}