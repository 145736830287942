.menu {
    .menu-list {
        line-height: 0.8;
        span {
            display: block;
            padding: $menu-list-link-padding;
        }
    }
}

.footer {
    margin: 0;
    min-height: 70px;
    padding-bottom: 12rem;;

    [class="block-networks"] {
        margin: 0 !important;
    }

    .navbar-item {
        padding: 0 0.75rem 0.5rem 0;
    }

    a {
        color: $white;
    }

    .image {
        img {
            width: 150px;

            @include desktop {
                width: 168px;
            }
        }
    }

    .social.buttons {
        justify-content: space-around;
    }

    .block-networks {
        flex-grow: 0;
        .icon {
            padding: 1rem;
        }

        a {
            display: inline-block;
        }

        & {
            @include animated_target($white-animation-border);
        }
    }
    .container {

        .columns {
            &:not(:last-child) {
                margin-bottom: 0;
                margin-top: $gap;

                .column {
                    &:last-child {
                        .title {
                            @include tablet {
                                text-align: right;
                            }
                        }
                    }
                }
            }
            .column:nth-child(2){
                @include desktop {
                    text-align: center !important;
                }
            }
        }
    }

    hr {
        background-color: $grey;
        display: block;
        margin: 1.5rem 0 0;
        height: 1px;
    }
}
