.navbar-brand {
    align-items: center;
    min-height: 4.25rem;

    @include fullhd {
        min-height: 5.25rem;
    }

    .navbar-item {
        &:hover {
            background-color: transparent !important;
        }
    }
}

#logo {
    height: 25px;

    @include tablet {
        height: 35px;
    }
    
    @include fullhd {
        height: 40px;
    }

    .blue-sky{
        fill: $blue-light;
    }
    .blue{
        fill: $blue;
    }
}

.has-navbar-transparent{

    #logo {
        .blue,
        .blue-sky {
            fill: $white;
        }
    }
}

.is-context-menu, 
.has-navbar-white{
    
    #logo {
        .blue {
            fill: $blue;
        }
        .blue-sky {
            fill: $blue-light;
        }
    }
}