.block-quote {

  blockquote {
    background-color: inherit;
    border: none;
    max-width: $tablet;
    margin: 0 auto;
    text-align: center;

    .quote-author {
      font-size: 1.15rem;
      font-weight: 500;

      @include tablet {
        text-align: right;
      }
    }

    .quote-body {
      color: inherit;
      font-size: 1.55rem;
      font-style: italic;
      font-weight: $subtitle-weight;
    }
  }
}

.block-vertical_tabs{
  .block-quote {
    blockquote  {
      padding: 0;
    }
  }
}
