.burgers {
    margin-left: auto;
    z-index: 2;

    .navbar-burger {
        color: $grey-black;
        background: transparent;
        border: none;
        margin-left: $gap / 2;
        height: $gap;
        width: $gap;

        @include desktop {
            height: 43px;
            width: 43px;
        }

        .fa-times {
            display: none;
        }

        &.is-active {
            background: transparent;
            color: $grey-black;

            .fa-bars,
            .fa-search {
                display: none;
            }
            .fa-times {
                display: block;
            }
        }
    }
}

.navbar-end {
    .burgers {
        .navbar-burger {
            &.search {
                display: none;

                @include desktop {
                    display: flex;
                }
            }
        }
    }
}

.has-navbar-transparent {

    .navbar-burger {
        color: $white;
        &:hover,
        &.is-active {
            color: $white;
        }
    }
}

.is-context-menu,
.has-navbar-white {
    
    .navbar-burger {
        color: $grey-black;
        &:hover,
        &.is-active {
            color: $grey-black;

        }
    }
}