.block-related_news {
    $image-height: auto;
    background-color: $grey-lighter;
    padding-block: 1rem;
    position: relative;

    .related-content {
        .image img {
            height: $image-height;
        }
    }

    .content-card {
        .columns {
            padding-left: 4rem;
            @include touch {
                display: flex;
                align-items: center;
                padding-left: 2rem;
            }
        }
        .image {
            height: 10rem;
            width: 100%;
            position: absolute;
            img {
                height: 100%;
                object-fit: cover;
            }
        }
        .legend {
            position: relative;
            min-height: 10rem;
        }
        .text-animated {
            opacity: 0;
            transition: opacity $duration-200 ease-out;
            p,a {
                @include transform(translateY, 80px, $duration-100, $default);
            }
            &.is-show-mobile {
                @include touch {
                    opacity: 1;
                }
            }

        }
        &:hover, &:focus {
            .text-animated {
                opacity: 1;
                transition: opacity $duration-200 ease-in;

                p,a {
                    @include transform(translateY, 0px, $duration-100, $default);
                }
            }
        }
    }

    .buttons {
        column-gap: 1rem;
    }
}