.block-news {
    $image-height: 352px;
    $image-height-hover: 452px;
    background-color: $grey-lighter;

    .buttons {
        justify-content: center;
        padding-bottom: 3rem;

        @include tablet {
            justify-content: right;
            margin-right: 3rem;
        }

        .deco-content {
            display: inline-block;
            padding-block: 10px;
            padding-inline: 15px;
        }
    }

    .container {
        align-self: center;

        @include mobile {
            margin-inline: 20px;
            align-self: end;

            & .box {
                border-radius: 0;
            }
        }
        h3 {
            @include touch {
                padding-inline: $gap;
            }
        }
        
        @include tablet {
            .container:not(.is-max-desktop) {
                max-width: 70vw;
            }
        }
    }

    .slider-news {
        position: relative;
        z-index: 1;

        .glide__slides {
            height: 520px;
        }

        .glide__slide {
            padding-top: 3rem;
        }


        .item {
            .card {
                transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
                position: relative;
            }

            .card-header {
                opacity: 1;
                position: absolute;
                z-index: 2;
                top: 0;
                width: 100%;

                &.columns {
                    margin: 0;
                    margin-right: 0.75rem;
                }

                p {
                    line-height: 1.2;
                    letter-spacing: 0;
                }
            }

            .card-image {
                transition: 0.2s all ease-out;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                width: 100%;
                position: absolute;
                height: $image-height;
                top: 0;
            }

            .card-content {
                opacity: 1;
                position: absolute;
                top: 0;
                width: 100%;
                padding-bottom: 4rem;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                transform: translateY($image-height - 100px);

                @include desktop {
                    opacity: 0;
                    transform: translateY(0);
                    .btn-plus {
                        display: none;
                    }
                }
            }

            @media (hover: hover) {
                .card:hover {
                    box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
                    transform: scale(1.1, 1.1) translateY(-2.3rem);
                    z-index: 3;
                }

                .card:hover.left-slide {
                    transform-origin: left;
                }

                .card:hover.right-slide {
                    transform-origin: right;
                }

                .card:hover .card-image {
                    height: $image-height-hover;
                }

                .card:hover .card-content {
                    opacity: 1;

                    .overlay-news {
                        transform: translateY($image-height);
                        z-index: 4;
                    }
                }

                a.has-text-white:hover, a.has-text-white:focus {
                    color: $white !important;
                }
            }
        }
    }
}