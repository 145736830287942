#navbar {

    .icon-text {
        width: 100%;
        flex-wrap: inherit;
        justify-content: space-between;

        .icon-click {
            transition: all $duration-100 ease-in-out;
            height: 40px;
            width: 40px;

            svg {
                transition: all $duration-100 ease-in-out;
            }
        }
    }

    .navbar-link.is-active a::before,
    .navbar-item.is-active a::before {
        @include underline(2rem, 2px, -4px, 0);
    }

    .small-deco {
        position: relative;

        &::after {
            @include underline(0%, 2px, -4px, 0.1s);
        }

        &:hover::after {
            width: 100% !important;
        }
    }

    .icon-text:hover .small-deco::after,
    .navbar-item:hover > .small-deco::after {
        width: 2rem;
    }

    .navbar-dropdown {
        &.navbar-dropdown-first {
            @media (hover: hover) {
                .icon-text:hover .icon-click {
                    color: $white;
                    background-color: $primary;
                }
            }
        }
        &.navbar-dropdown-second {
            @media (hover: hover) {
                .icon-text:hover .icon-click {
                    color: $primary;
                    background-color: $white;
                }
            }
        }
    }

    .hover-dropdown {
        > .navbar-link {
            .icon-click {
                svg {
                    @include transform(rotate, -$rotate-180, $duration-100, $default);
                }
            }
        }
    }
}