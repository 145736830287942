.skip-intro {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;

    &:active,
    &:focus {
        position: relative;
        width: auto;
        height: auto;
        left: auto;
    }
}

.has-navbar-transparent {
    .skip-intro {
        color: $white;
    }

    &.has-navbar-white {
        .skip-intro {
            color: $primary;
        }
    }
}
