.alert {
    
    &.notification {
        color: $white;
        
        .container {
            width: 100%;
    
            @include touch {
                padding: 0 0.5rem;
            }
    
            .delete {
                border: 1px solid $white;
                border-radius: 0;
                top: calc(50% - 10px);
                right: 0;
                position: absolute;
            }
            
            .icon {
                &.is-size-8 {
                    font-size: 8px !important;
                }
            }
        }
    }
}