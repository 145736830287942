@mixin before-after($top, $left, $width, $height, $z-index, $bg-color) {
    content: "";
    position: absolute;
    top: $top;
    left: $left;
    width: $width;
    height: $height;
    z-index: $z-index;
    background: $bg-color;
}

@mixin center {
    display: flex;
    align-items: center;
    justify-content: center;
}

#organigrama.container {
    min-width: 1350px;
    max-width: 1450px;
}

.block-organization_chart {
    margin-inline: 0.5rem !important;
    overflow-y: scroll;
    scroll-snap-type: x mandatory;

    @include mobile {
        zoom: 90%;
    }

    @include tablet-only {
        zoom: 90%;
    }

    .org-tree-content {
        scroll-snap-align: center;
    }

    // Pisa las clases que implementan el botón de mira para cambiar los colores
    // de todos los elementos menos root. 
    .grid-sides,
        .dependencies {
            .deco-border-alt .deco-content-alt {

                &:before,
                &:after,
                & *:before,
                & *:after {
                    background-image: $white-animation-border;
                }
            }

            .deco-border-hover-alt:hover .deco-content-alt {
                background-color: $primary;
            }
        }

    .box {
        margin-bottom: 0;
        border: 1px solid $primary;
        border-radius: 10px;
        position: relative;
        z-index: 1;

    }

    h3, h6 {
        font: {
            size: 1em;
            weight: 600;
        }
        color: $primary;
        text-align: start;
    }

    .root {
        position: relative;
        grid-column: 5/9;
        justify-self: center;
        @include center;
    }
    // Padre 1
    .level-0 {

        .box {
            &.office-url {
                @extend .is-clickable;
                @extend .is-shadowless;
                @include center;
                flex-direction: column;
                text-align: center;
                background-color: $primary;
                padding-inline: 2.5rem;
                min-height: 130px;
            }
            .is-overlay {
                opacity: 0;
                border-radius: 8px;
                @include center;
    
                &:hover {
                    opacity: 1;
                    background-color: $primary-light;
                    color: $primary;
                    border: 1px solid transparent;
                }
            }
        }
        padding: 0;
        @include center;

        span.title, h4 {
            color: $white;
        }
        a {
            font-size: $size-5;
        }
        // Línea vertical
        &::before {
            @include before-after(50%, 50%, 1px, 80%, -1, $blue);
        }
        // Línea horizontal
        &::after {
            @include before-after(50%, auto, 250%, 1px, -1, $blue);
        }
    }

    // Padre 2
    .dependencies >.level-1 {
        >.box {
            background-color: $primary-light;
            color: $primary;
            text-align: center;
            .is-overlay {
                opacity: 0;

                &:hover{
                    opacity: 1;
                    background-color: $primary;
                    color: $white;
                    border: 1px solid transparent;
                }
            }

                span {
                    opacity: 1;
                }
                .is-overlay {
                    @include center;
                    text-decoration: none;
                    padding: 0.5rem;
                    border-radius: 8px;
                }
        }
    }

    // Sector 1
    /* esquema de colores de los recuadros blancos en normal
    y azules en hover (Todos menos los padres 1 y 2) */
    .advice.level-1, .dependencies .level-2 {
        .box {
            background-color: $white;
            color: $primary;
            p {
                color: $black;
                font-weight: 400;
                font-size: .85rem;
            }

            .is-overlay {
                opacity: 0;
                display: flex;
                align-items: center;
                text-decoration: none;
                padding: 0.5rem;
                border-radius: 8px;
                padding-left: 1rem;

                &:hover{
                    opacity: 1;
                    background-color: $primary;
                    color: $white;
                    border: 1px solid transparent;
                }
            }

            span {
                opacity: 1;
            }
        }
    }

    .advices {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        row-gap: 1rem;
    }

    .advice.level-1 {
        position: relative;
        align-self: stretch;
    }

    .grid-sides {
        @include center;
        flex-direction: column;
        row-gap: 1rem;
        height: 100%;
        width: 100%;
        background-color: $white;
    }

    .grid-right {
        grid-column: 10 / 13;
        grid-row: 1 / 2;
    }

    .grid-left {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
    }


    /* empiezan clases de curvas */
    .group-left {
        &:first-child::after {
            @include before-after(50%, calc(100% - 5rem), 6rem, 75%, 0, $white);
            border-top: 1px solid $blue;
            border-right: 1px solid $blue;
            border-radius: 0 16px 0 0;
        }

        &:last-child::after {
            @include before-after(0, 100%, 1rem, 50%, 0, $white);
            border-bottom: 1px solid $blue;
            border-right: 1px solid $blue;
            border-radius: 0 0 16px 0;
        }
        &:not(:first-child):not(:last-child)::after {
            @include before-after(0, calc(100% - 1px), calc(1rem + 1px), calc(100% + 2rem - 1px), 0, $white);
            border-right: 1px solid $blue;
        }

        &:not(:first-child):not(:last-child)::before {
            @include before-after(50%, 100%, 1rem, 1px, 1, $blue);
        }
    }

    .group-right {
        &:first-child::after {
            @include before-after(50%, auto, 6rem, 4rem, 0, $white);
            right: calc(100% - 5rem);
            border-top: 1px solid $blue;
            border-left: 1px solid $blue;
            border-radius: 16px 0 0 0;
        }

        &:last-child::after {
            @include before-after(0, auto, 1rem, 50%, 0, $white);
            right: 100%;
            border-bottom: 1px solid $blue;
            border-left: 1px solid $blue;
            border-radius: 0 0 0 16px;
        }

        &:not(:first-child):not(:last-child)::after {
            @include before-after(0, auto, calc(1rem + 1px), calc(100% + 2rem - 1px), 0, $white);
            right: calc(100% - 2px);
            border-left: 1px solid $blue;
        }

        &:not(:first-child):not(:last-child)::before {
            @include before-after(50%, auto, 1rem, 1px, 1, $blue);
            right: 100%;
        }
    }

    // Sector 2
    .advice-flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        width: 50%;
        column-gap: 4rem;
        margin-inline: auto;
        cursor: default;
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: stretch;
        grid-auto-rows: 1fr;


        .advice-2.level-2 {

            .box {
                height: 100%;
            }

            margin-top: 1rem;

            &:nth-child(odd) {
                .linea {
                    background-color: $blue;
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    z-index: 4;
                }
                &:last-child {
                    .linea {
                        width: 26%;
                        z-index: 1;
                    }
                }
            }
            &:nth-child(even) {
                .linea {
                    display: none;
                }
            }

            &:nth-child(odd):last-child .box {
                z-index: 1;
            }
        }
    }

    .dependencies {
        display: flex;
        flex-flow: row wrap;
        max-width: 100%;
        margin-top: 2rem;

        .level-1 {
            position: relative;

            .linea {
                @extend %overlay;
                left: -8px;
                right: 50%;
                width: 41px;
                z-index: 2;
                background-color: $white;
                border-right: 1px solid $blue;
                background-color: $white;
                border-left: 1px solid $blue;
                border-right: none;
                left: 50%;
                z-index: 1;
            }


            >.box {
                width: Max(40%, 14rem);
                margin-bottom: 10px;
                z-index: 3;
                border: 1px solid $primary-light;


                a {
                    display: none;
                }

                h6 {
                    font-size: $size-5;
                    text-align: center;
                }

                margin-inline: auto;
                width: Min(20%, 20rem);
                position: relative;
                z-index: 4;
                a {
                    display: block;
                }

                a {
                    position: relative;
                    text-decoration: underline;
                    font-size: $size-6;
                    position: absolute;
                }
            }
            .dependencies {
                justify-content: flex-start;
                flex-wrap: nowrap;
                position: relative;
                column-gap: 1rem;
                margin-left: 0;

                display: flex;
                align-items: end;
                margin-top: 4rem;

                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 5%;
                    width: 56%;
                    height: 20%;
                    z-index: 3;
                    background: transparent;
                    border-radius: 10px;
                    border: 1px solid $blue;
                    border-bottom: none;
                }

                >.level-2 {
                    &::before {
                        border-left: 1px solid $blue;
                        content: "";
                        position: absolute;
                        height: 100%;
                        left: 50%;
                        top: -34px;
                    }
                }
                >.level-2:first-child, 
                >.level-2:nth-child(2), 
                >.level-2:nth-child(3), 
                >.level-2:last-child {
                    &::before {
                        display: none;
                    } 
                }

                .level-2 {
                    position: relative;
                    margin-top: 0.25rem;

                    &:first-child {

                        &::after {
                            border-top: 1px solid $blue;
                            content: "";
                            position: absolute;
                            top: 20%;
                            right: -60%;
                            width: 100%;
                        }
                    }

                    .box {
                        padding: 0.75rem;
                        position: relative;
                        margin: 0 auto;
                        max-width: 146px;
                        height: 146px;
                        width: 100%;
                        z-index: 3;
                    }

                    .dependencies {
                        &::before {
                            top: -23%;
                            width: 90%;
                            height: 50%;
                        }
                        &::after {
                            content: "";
                            position: absolute;
                            border-left: 1px solid $blue;
                            left: 50%;
                            height: 100%;
                            top: -50%;
                        }

                        .level-3 {

                            .box {
                                &::after {
                                    border-left: 1px solid $blue;
                                    content:"";
                                    left: 50%;
                                    height: 35px;
                                    position: absolute;
                                    top: -35px;
                                }
                            }

                            &:first-child, 
                            &:last-child {
                                .box {
                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}