.block-accordion {
    background-color: $primary;
    padding-block: $gap * 2;

    .tabs-section {
        color: $white;

        .title {
            color: $white;
        }
    }

    .menu-container {
        .glide__arrow {
            background-color: $primary;
            @extend .btn.btn--grey-dark;
            &:hover {
                background-color: rgba($white, 0.5);
            }
        }
    }

    .tabs {
        @extend .is-fullwidth;
        /* Estilos para acomodar el responsive de las tabs
        en la estructura actual. Remover si se cambia*/

        &::-webkit-scrollbar {
            width: 0;
        }

        ul {
            margin-left: 0;
        }

        li {
            margin-right: 0.25rem;
            margin-left: 0.25rem;
            border-bottom: 2px $tabs-border-bottom-style $white;

            &.is-active {
                border-bottom-color: $blue-light;

                a {
                    color: $blue !important;
                    background-color: $white;
                }
            }

            &:hover {
                border-bottom-color: $blue-light;
            }
        }
    }

    .tabs-container {
        @include touch {
            min-height: 20vh;
        }
    }

    table {
        background-color: transparent;
        caption-side: bottom;
        color: $white;

        th,
        caption {
            color: $white;
            margin-block: 0.25rem;
        }

        tbody {
            color: $white;

            @media (hover: hover) {
                tr:hover th,
                tr:hover td {
                    background-color: $white;
                    color: $blue;
                }
            }
        }
    }

      /* Modifica color de form para ajustarlo a la tabera */
    .block-form {
        .deco-border .deco-content {
            &:before,
            &:after,
            & *:before,
            & *:after {
                background-image: $white-animation-border;
            }
        }
        
        .deco-border {
            color: $white;
            &:hover > span {
                color: $primary;
                background-color: $white;
            }
        }
    }

    .block-columns,
    .block-paragraph {
        padding: 0;

        h3,
        h4 {
            color: $white  !important;
        }

        a {
            color: $primary-light;
            &:hover {
                color: $primary-light;
            }
        }

        iframe {
            float: left;
            width: 50%;
            height: 30vh;
            padding-top: 0.5rem;
            margin-bottom: 0.5rem;

            @include tablet {
                padding-right: 1rem;
            }

            @include mobile {
                float: none;
                width: 100%;
            }
        }
    }

    .button {
        font-weight: 600;

        &.is-blue {
            @extend .is-blue-invert;
        }
    }
}