.block-related_content {

    .content-grid {
        @include tablet {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1rem;
        }
    }

    // Primera destacada vertical 
    .is-tile-1:first-child {
        grid-row: 1 / 3;
        grid-column: 1 / 2;
    }

    // Primera destacada horizontal
    .is-tile-2:first-child {
        grid-row: 1 / 2;
        grid-column: 1 / 3;
    }

    // La clases is-tile-3 corresponde a Última destacada vertical 
    // La clases is-tile-4 corresponde a Última destacada horizontal
    // Data-number genera valores según el número de tarjetas: 1 (<=6), 2 (=7), 3 (8 o más)
    // La última destacada vertical ocupa las filas 1 y 2 cuando hay 7 o menos elementos, 
    // luego pasa a 2 y 3
    // La última destacada horizontal está en la fila 2 cuando hay 6 o menos elementos
    // Si hay más pasa a la última fila. 

    // Última destacada vertical
    [data-number="1"],
    [data-number="2"] {
        .is-tile-3:last-child {
            grid-row: 1 / 3;
            grid-column: 3 / 4;
        }
    }

    [data-number="3"] {
        .is-tile-3:last-child {
            grid-row: 2 / 4;
            grid-column: 3 / 4;
        }
    }

    // Última destacada horizontal 
    [data-number="1"] {
        .is-tile-4:last-child {
            grid-row: 2 / 3;
            grid-column: 2 / 4;
        }
    }

    [data-number="2"],
    [data-number="3"] {
        .is-tile-4:last-child {
            grid-row: 3 / 4;
            grid-column: 2 / 4;
        }
    }


    .content-card {
        @include mobile {
            margin-bottom: 1rem;
        }
        .legend {
            position: absolute;
            padding-top: 1rem;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: 100%;
            justify-content: space-between;
        }

        .image {
            @include tablet {
                height: 100%;
            }

            img {
                display: block;
                height: 100%;
            }
        }

        .text-animated {
            opacity: 0;
            transition: opacity $duration-200 ease-out;
            &.is-show-mobile {
                @include touch {
                    opacity: 1;
                    padding-bottom: $gap;
                }
            }
        }

        &:hover, &:focus {
            .text-animated {
                opacity: 1;
                transition: opacity $duration-200 ease-in;
            }
        }

        .overlay-link:hover {
            cursor: url('../img/circle.svg'), auto;
        }

        @include desktop {
            .legend .btn-plus {
                display: none;
            }
        }
    }

}
