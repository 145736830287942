%corners {
    &:before {
        background-position: left top;
        top: 0;
        left: 0;
    }
    &:after {
        background-position: right top;
        top: 0;
        right: 0;
    }
    & *:before {
        background-position: right bottom;
        right: 0;
        bottom: 0;
    }
    & *:after {
        background-position: left bottom;
        bottom: 0;
        left: 0;
    }
}

// Estas clases se usan para los botones que comienzan con la mira
// y la animación la completa y cambia el color
.deco-border .deco-content {
    position: relative;
    border-radius: 3px;
    padding-block: 4px;
    padding-inline: 4px;

    &:before,
    &:after,
    & *:before,
    & *:after {
        content: "";
        position: absolute;
        background-image: $blue-animation-border;
        background-repeat: no-repeat;
        height: 10px;
        width: 10px;
        animation: SightOut .2s ease;
    }

    @extend %corners;
}
.deco-border-hover:hover .deco-content {
    color: $white;
    background-color: $primary;
    transition: .2s ease;
    transition-delay: .3s;

    &:before,
    &:after,
    & *:before,
    & *:after {
        height: 50%;
        width: 50%;
        animation: SightIn .2s ease;
    }
}

// Estas clases se usan en el organigrama
// Al hacer hover sobre a cambia el color y se arma la mira
.deco-border-alt .deco-content-alt {
    position: relative;
    border-radius: 3px;
    padding-block: 10px;
    padding-inline: 10px;

    &:before,
    &:after,
    & *:before,
    & *:after {
        content: "";
        position: absolute;
        background-image: $blue-animation-border;
        background-repeat: no-repeat;
        animation: TargetOut .6s ease;
    }

    @extend %corners;
}

.deco-border-hover-alt:hover .deco-content-alt{
    background-color: $primary-light;

    &:before,
    &:after,
    & *:before,
    & *:after {
        animation: TargetIn .6s ease forwards;
    }
}

// Se usa en los casos que empiezan sin bordes y la animación crea
// la mira sobre el elemento (paginador, lang-switcher, etc)
@mixin animated_target($border-color) {
    .btn-border {
        position: relative;
        border-radius: 3px;
        font-weight: 600;
    }

    .btn-border:before,
    .btn-border:after,
    .btn-border *:before,
    .btn-border *:after {
        content: "";
        background-image: $border-color;
        background-repeat: no-repeat;
        position: absolute;
        animation: TargetOut .2s ease;
    }

    .btn-border:hover:before,
    .btn-border:hover:after,
    .btn-border:hover *:before,
    .btn-border:hover *:after {
        animation: TargetIn .2s ease forwards;
    }

    .btn-border {
        @extend %corners;
    }
}

// Se usa para elementos que tienen la mira en forma estática (dirección en contact, por ejemplo)
@mixin target_not_button ($border-color) {
    .btn-border {
        position: relative;
        border-radius: 3px;
        font-weight: 600;
    }
    .btn-border:before,
    .btn-border:after,
    .btn-border *:before,
    .btn-border *:after {
        content: "";
        background-image: $border-color;
        background-repeat: no-repeat;
        position: absolute;
        height: 10px;
        width: 10px;
    }

    .btn-border {
        @extend %corners;
    }
}


