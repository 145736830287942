.block-vertical_tabs,
.block-accordion {

    /* Bloques internos de taberas y responsive*/
    [class*="block-"] {
        margin-block: $gap / 2;
        @include touch {
            margin-inline: 0;
        }
    }
    [class*="block-"]:first-child {
        margin-top: 0;
    }
    [class*="block-"]:last-child {
        margin-bottom: 0;
    }
    .block-paragraph:not(:last-child) {
        margin-bottom: $gap / 2;
    }

    .tabs-section {
        @include touch {
            margin-inline: 1rem;
        }
    }

    .title {
        @extend .is-4;
        font-weight: 500;
    }

    .menu-container {
        position: relative;

        .glide__arrow {
            &.glide__arrow--left {
                left: -1rem;
                @include widescreen {
                    left: -4rem;
                }
            }
            &.glide__arrow--right {
                right: -1rem;
                @include widescreen {
                    right: -4rem;
                }
            }
            &.btn.btn--size {
                padding: 3px 4px;
                .icon {
                    font-size: 8px;
                }
                @include tablet {
                    padding: 7px 8px;
                    .icon {
                        font-size: 16px;
                    }
                }
            }
            & .icon {
                @include touch {
                    opacity: 1;
                }
            }
        }
    }

    .tabs {
        position: relative !important;
        overflow-x: hidden;

        .menu {
            position: relative;
        }

        li {
            min-width: 6.3rem; // ver responsive
            @include tablet {
                min-width: 12rem;
            }

            &.is-active {
                border-color: $tabs-link-active-border-bottom-color;
                background-color: $tabs-link-active-border-bottom-color;
                border-radius: 2px;
                a {
                    color: $white;
                    font-weight: 500;
                }
            }
        }
    }

    .tabs-container {
        display: grid;
        grid-template-areas: "content";
        height: 100%;
    }

    .tab-container {
        opacity: 0;
        visibility: hidden;
        grid-area: content;
        height: inherit;
        transition: opacity 0.3s ease-in-out;

        &.is-active {
            visibility: visible;
            opacity: 1;
        }
    }

    .tab-content {
        height: 100%;
        max-height: 455px;
        padding-right: 0.5rem;
        overflow-y: scroll;

        @include tablet {
            padding-right: 1rem;
        }
        @include desktop {
            padding-right: 1.5rem;
            &::-webkit-scrollbar {
                width: 0.2rem;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $grey-dark;
                border-radius: 10px;
            }
        }
        .richtext-image {
            max-height: 455px;
        }
    }
    .block-form {
        transition: none;

        .buttons {
            justify-content: left;
        }

        a {
            transition: none;
        }
    }

    .block-columns,
    .block-quote,
    .block-table,
    .block-form {
        clear: both;
    }

    .block-paragraph, .block-column {
        @extend .content.is-normal;
    }
}