.home {
    .block-services {
        .card {
            max-height: 90vh;
        }
    }
}

.block-services {
    $text-container: 70%;
    $bg-color-hover-bottom: rgba(10, 10, 10, 0.80);
    $bg-color-hover-top: rgba(10, 10, 10, 0);

    .card {
        overflow: hidden;
        transition: transform $duration-500 ease-out;
        max-height: 50vh;

        a.overlay-link {
            z-index: 4;
        }

        .container {
            z-index: 1;
        }

        .card-header {
            position: absolute;
            left: 0;
            right: 0;
            z-index: 3;
            box-shadow: none;
            letter-spacing: 3px;
            h4 {
                padding-left: 2rem;
            }
        }

        .image img {
            height: auto;
            width: 100%;
        }

        .overlay-container {
            color: $white;
            &>a,
            strong {
                color: $white;
            }
            p {
                display: block;
            }
            .is-overlay {
                display: flex;
                flex-flow: column;
                padding: 2rem;
                row-gap: 1rem;
                justify-content: flex-end;
                align-items: flex-start;
                @include desktop {
                    p,a {
                        opacity: 0;
                        transform: translateY(0) scale(0.95);
                    }
                }
            }
            @include desktop {
                .is-overlay .btn-plus {
                    display: none;
                }
            }
        }
        &:hover,
        &:focus {
            z-index: 1;
            .is-overlay {

                p,span,a {
                    animation: $duration-200 ease-in forwards fadeIn;
                }
            }
            .overlay {
                &:after {
                    @extend %overlay;
                    content: "";
                    background: $bg-color-hover-bottom;
                    background: linear-gradient(0deg, $bg-color-hover-bottom 20%, $bg-color-hover-top 100%);
                }
            }
        }
    }
}

