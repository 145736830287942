.pagination-buttons {
    $button-hover-color: $blue-light;
    $button-text-hover-color: $white;
    $border-color: transparent;
    padding: $gap * 2 0;

    .button-container {
        margin-right: 0.25rem;

        &.is-selected {
            @include target_not_button($blue-animation-border); 
        }
        
        &:not(.is-selected) {
            @include animated_target($blue-animation-border);
        }

        .button {
            background-color: transparent;
            color: $primary;
            margin: 0;
            border-top: $border-color;
            border-bottom: $border-color;
            border-radius: 0;

            &.direction {
                border-radius: 14px;
            }

            &:first-child {
                border-left: $border-color;
                border-right: $border-color;
            }

            &:last-child {
                border-right: $border-color;
                border-left: $border-color;
            }
            @include mobile {
                &:nth-child(2) {
                    border-left: $border-color;
                }
            }
        }
    }
}
