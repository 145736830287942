.block-title {
    @extend .content;
    @extend .is-medium;

    h2 {
        @extend .has-text-link;
        @extend .decoration;
        @extend .blue;
        font-weight: 400;
    }

}
