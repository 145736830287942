// Generales
@import "general/fonts";
@import "general/variables";
// Librerías
@import "~bulma";
@import "~bulma-tooltip";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";
// Animaciones
@import "general/animated-button";
@import "general/animations";
@import "general/decoration";
// Encabezado
@import "partials/header";
@import "partials/header-hero";
@import "partials/header-split";

// Parciales
@import "partials/breadcrumb";
@import "partials/content";
@import "partials/errors";
@import "partials/finder";
@import "partials/footer";
@import "partials/home-shortcuts";
@import "partials/home";
@import "partials/is-rounded-animate";
@import "partials/landing";
@import "partials/menu-social";
@import "partials/menu-suggested";
@import "partials/new-teaser";
@import "partials/organization-chart";
@import "partials/pagination";
@import "partials/profile";
@import "partials/results";
@import "partials/sharing";
@import "partials/sidebar";
@import "partials/titles";
// Components
@import "components/blocks";
@import "components/block-alert";
@import "components/block-authority";
@import "components/block-banner";
@import "components/block-clients";
@import "components/block-column";
@import "components/block-contact";
@import "components/block-document";
@import "components/block-collapsible";
@import "components/block-form";
@import "components/block-gallery";
@import "components/block-horizontal-tabs";
@import "components/block-link";
@import "components/block-media";
@import "components/block-news";
@import "components/block-paragraph";
@import "components/block-products";
@import "components/block-quote";
@import "components/block-related";
@import "components/block-related_news";
@import "components/block-services";
@import "components/block-slider";
@import "components/block-suggested";
@import "components/block-table";
@import "components/block-tabs";
@import "components/block-title";
@import "components/block-vertical-tabs";

// Navegación principal
@import "partials/burger";
@import "partials/language-switcher";
@import "partials/logo";
@import "partials/navbar-dropdown";
@import "partials/navbar-item-icon";
@import "partials/navbar-items";
@import "partials/navbar";
@import "partials/skip-intro";

// Basic global styles
body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

#wrapper {
    flex: 1;
}

.is-wrapper {
    @include desktop {
        padding-right: $gap * 4;
    }
}

.is-reverse-mobile {
    @include mobile {
        flex-direction: column-reverse;
        display: flex;
    }
}
