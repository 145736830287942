// Escala de titulos
// viewport > widescreen

.title {

    &.ani-top {
        animation-name: slicetop;
        animation-duration: 1s;
    }

    &.is-2,
    &.is-3 {
        font-weight: 400;
    }
}

.subtitle {
    &.ani-opacity {
        animation-name: opacity;
        animation-duration: 1s;
    }
}
