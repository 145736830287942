.block-collapsible {
    /* Bloques internos de acordeón */
    [class*="block-"] {
        margin-block: $gap / 2;
        @include touch {
            margin-inline: 0;
        }
    }

    [class*="block-"]:first-child {
        margin-top: 0;
    }

    [class*="block-"]:last-child {
        margin-bottom: 0;
    }

    .block-paragraph:not(:last-child) {
        margin-bottom: $gap / 2;
    }

    .block-paragraph, .block-column {
        @extend .content.is-normal;
    }

    table {
        background-color: transparent;
    }
    .block-columns,
    .block-quote,
    .block-table,
    .block-form {
        clear: both;
    }
}

.accordions {

    h3.title {
        @extend .is-size-4;
        color: $primary;
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    .message.is-primary {
        background-color: $grey;
        margin-bottom: 1px;

        .block-form {
            .buttons {
                    justify-content: left;
            }
        }

        .message a:not(.button):not(.tag):not(.dropdown-item) {
            color:inherit
        }
    }

    .message.is-primary:last-child {
        margin-bottom: 0;
    }

    .message-header{
        padding: 0;
        border-radius: 0%;
        line-height: 1.5rem;
        height: 40px;
    }

    .accordion {
        span.collapsible-title {
            padding-left: 1rem;
            font-weight: 400;
            line-height: 1.5rem;

            @include mobile {
                width: 312px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        .accordion-header-icon {
            display: flex;
            align-items: center;
            padding-block: 0.25rem; 
            padding-inline: 0.5rem;
            background-color: $primary-dark;
            height: 100%;
            span.icon {
                transition: transform 200ms ease-out;
            }
        }

        .accordion-content {
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            transition: max-height 100ms ease-out;
        }

        &.active {
            .accordion-header-icon {
                span.icon {
                transform: rotate(-180deg);
                transition: transform 100ms ease-in;
            }
            }
            .accordion-content {
                max-height: 480px;
                opacity: 1;
                overflow-y: auto;
                transition: max-height 100ms ease-in;
                padding-inline: 1rem;
                padding-block: 1.5rem;
            }
        }
    }

    .richtext-image {
        max-height: 480px;
    }
}
