// Underline
@mixin underline($width, $height, $bottom, $seg) {
    content: "";
    width: $width;
    height: $height;
    position: absolute;
    bottom: $bottom;
    left: 0;
    border-radius: 5px;
    background-color: $primary-light;
    transition: width $seg ease-in-out;
}

.decoration {
    $deco-width: 4vw;
    $deco-bottom: -6px;
    $deco-height: 4px; 
    position: relative;
    cursor: default;
    display: inline-block;

    &::after {
        @include underline($deco-width, $deco-height, $deco-bottom, 0.2s);
        @include mobile {
            width: $deco-width * 3;
        }
    }

    &.white {
        &::after {
            background-color: $white;
        }
    }

    &.blue {
        &::after {
            background-color: $primary-light;
        }
    }

    @media (hover: hover) {
        &:hover::after {
            width: 100%;
        }
    }
}

.short-deco {
    position: relative;

    &::after {
        @include underline(0%, 2px, -4px, 0.1s);
    }

    &:hover::after {
        width: 100% !important;
    }
}

.short-deco::after,
.short-deco::after {
    width: 2rem;
}

.overlay-link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

