.block-related_news,
.block-suggested_link {
    $image-height: auto;
    .related-content {
        .image img {
            height: $image-height;
        }
    }
}

.block-related_news,
.block-suggested_link {
    position: relative;
    .card {
        height: 100%;
        transition: box-shadow ease-in .3s;
        @media (hover: hover) {
            &:hover {
                transition: box-shadow ease-out .3s;
                @include diffuse(8, rgba($grey-black, 0.12), true);
            }
        }
    }
    .content-card {
        .columns {
            padding-left: 4rem;
            @include touch {
                display: flex;
                align-items: center;
                padding-left: 2rem;
            }
        }
        .image {
            height: 10rem;
            width: 100%;
            position: absolute;
            img {
                height: 100%;
                object-fit: cover;
            }
        }
        .legend {
            position: relative;
            min-height: 10rem;
        }
        .text-animated {
            opacity: 0;

            p,a {
                animation-name: fadeIn;
                animation-duration: $duration-300;
                animation-timing-function: ease-in;
            }
            &.is-show-mobile {
                @include touch {
                    opacity: 1;
                }
            }

        }
        &:hover, &:focus {
            .text-animated {
                opacity: 1;
                transition: opacity $duration-200 ease-in;

                p,a {
                    @include transform(translateY, 0px, $duration-100, $default);
                }
            }
        }
    }

    .buttons {
        column-gap: 1rem;
    }
}


.block-suggested_link {
    
    .content-card {
        position: relative;
        
        .columns {
            padding-left: 1rem;

            h4 {
                margin-bottom: 0;
            }

            .is-one-fifth-desktop {
                text-align: right;

                a {
                    margin-right: 1rem;
                }
            }
        }
    }
}
