.language_switcher {
    display: flex;

  /*   a {
        position: relative;
    } */

    .navbar-item {
        padding-block: 0.5rem; 
        padding-inline: 0.5rem;
        min-width: 40px;
        display: block;
        text-align: center;
        text-transform: uppercase;
    }
}

.has-navbar-transparent {
    .language_switcher {
        .button-holder:first-child {
            padding-right: 0.25rem;
        }
        .button-holder.active{
            @include target_not_button($white-animation-border);
        }
        .button-holder:not(.active) {
            @include animated_target($white-animation-border);
        }
    }

    &.has-navbar-white {
        .button-holder.active{
            @include target_not_button($grey-animation-border);
        }
        .button-holder:not(.active) {
            @include animated_target($grey-animation-border);
        }
    }
}
