.block-vertical_tabs {
    background-color: $grey;
    padding-block: $gap * 2;

    .tabs-section {
        background-color: initial;
        color: $blue-dark;

        .title {
            color: $primary;
        }
    }

    .viewer {
        padding-block-end: $gap;

        &.out {
            transition: opacity 0.3s ease-out;
            opacity: 0;
        }
        &.in {
            transition: opacity 0.3s ease-in;
            opacity: 1;
        }
    }

    .menu-container {
        .glide__arrow {
            background-color: $white;
            border-color: $primary;
            color: $primary;
        }

        @include tablet {
            display: block;
            width: 25%;

            .glide__arrow {
                display: none;
                width: 25%;
            }
        }
    }

    .holder {
        @extend .columns;
        margin: 0;
        gap: 2rem;
    }

    .tabs {
        @include tablet {
            display: block;
            width: 100%;
            height: 100%;
            padding: 0;
            ul {
                margin: 0;
                height: 100%;
                display: grid;
                align-content: flex-start;
                justify-content: stretch;
                &.menu {
                    left: 0;
                }
            }
        }

        @include desktop {
            ul {
                flex-direction: column;
            }
            
        }

        li {
            position: relative;
            width: 100%;
            margin-right: 0.25rem;
            margin-left: 0.25rem;
            border-bottom: 2px $tabs-border-bottom-style $blue;
            overflow: hidden;

            a {
                color: $blue;
            }

            @include tablet {
                margin-right: 0;
                margin-left: 0;
                border-bottom: none;
                margin-bottom: $gap/4;
                a {
                    justify-content: flex-start;
                    &:hover {
                        color: $blue;
    
                        &:before {
                            background-color: $blue-light;
                        }
                    }
                    &:before {
                        background-color: $blue;
                        content: "";
                        position: absolute;
                        left: 0;
                        transition: opacity 0.3s ease-in-out;
                        @include tablet {
                            min-height: 100%;
                            width: 2px;
                        }
                        @include mobile {
                            bottom: 0;
                            width: 50%;
                            height: 2px;
                        }
                    }
                }
            }

            
            &.is-active {
                background-color: $blue;

                a:before {
                    background-color: $blue-light;
                    @include mobile {
                        width: 75%;
                    }
                }
                a:hover {
                    color: $white;
                }
            }
        }
    }

    .tabs-container {
        @extend .column;
        padding: 0;
    }

    .tab-container {
        color: initial;
    }

    .block-table {
        table {
            background-color: transparent;

            tbody,
            th,
            td {
                color: initial !important;
            }

            td {
                padding-left: 0;
            }
        }
    }

    .block-paragraph {
        a {
            transition: none;
        }

        iframe {
            height: 30vh;
            float: right;
            width: 50%;
            padding-block: 0.5rem;
            @include tablet {
                padding-left: 1rem;
            }
            @include mobile {
                float: none;
                width: 100%;
            }
        }
    }
}
