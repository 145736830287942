.block-form {
    .buttons {
      justify-content: center;

      .deco-content {
        display: inline-block;
        padding-block: 6px;
        padding-inline: 10px;
      }
    }

  .modal {
    max-height: 0;
    animation-duration: $duration-500;
    animation-name: slideout;

    .modal-content {
      display: flex;
      align-items: center;
      justify-content: center;

      iframe {
        height: 80vh;
      }
    }
  }
}

.modal {
  max-height: 0;

  &.is-active {
    animation-name: fadeIn;
    animation-duration: $duration-300;
    animation-timing-function: ease-in;
    max-height: 100vh;
  }
}


