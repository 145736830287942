.template-404,
.template-500 {
    section.container {
        padding-top: 10rem;
        padding-bottom: 5rem;
    }
}

.template-500 {
    .upper {
        @include mobile {
            padding-left: 1rem;
        }
    }
    .lower {
        @include mobile {
            padding-left: 1rem;
        }
        width: 100%;
        background-color: #014990;
        color: #fafafa;
      }
      .lower img {
        height: 58px;
        width: 150px;
      }
      nav {
        min-height: 70px;
        padding-top: 1rem;
        font-size: 1rem;

        @include desktop {
        padding-bottom: 7rem;
        padding-top: 5rem;
        }
      }
      footer {
        font-size: 0.75rem;
        padding-top: 1rem;
        padding-bottom: 1.5rem;
        min-height: 75px;
      }
      hr {
        background-color:  hsl(0, 0%, 60%);
        display: block;
        margin: 1.5rem 0 0;
        height: 1px;
      }
      a {
        color: #FFFFFF;
        text-decoration: none;
      }
  }


