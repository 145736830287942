.social {
    justify-content: start;

    @include tablet {
        justify-content: flex-end;
    }
    .is-rounded {
        border-color: $grey-dark;
        border-radius: 50%;
        padding-left: 1em;
        padding-right: 1em;

        .icon {
            color: $grey-dark;
        }
    }
}
