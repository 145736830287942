// Colores plenos
$white: #fafafa;
$black: #000000;

// Grises
$grey: hsl(0, 0%, 95%);
$grey-black: hsl(0, 0%, 22%);
$grey-dark: hsl(0, 0%, 40%);
$grey-light: hsl(0, 0%, 80%);
$grey-lighter: hsl(0, 0%, 90%);
$grey-transparent: hsla(0, 0%, 98%, 0.8);
$red: #D13902;


// Azules
$blue: #014990;
$blue-light: #7DCBED;
$blue-dark: #003362;

$primary: $blue;
$primary-light: $blue-light;
$primary-dark: $blue-dark;
$danger: $red;

// Esto no se está usando
// Computed Hero colors
$custom-blue: ( "blue": ( $blue, $white, ), "blue-black": ( $blue, $grey-black, ), );
$hero-colors: $custom-blue;
$modal-content-width: 60vw;

$icon-color: $white;
$icon-background: #8894ad;

// Fonts
// $subtitle-family: $family-primary;
// $title-size: 2re
$family-primary: 'BaiJamjuree', sans-serif;
$family-secondary: "Roboto", Arial;
$title-family: $family-primary;
$title-font: $family-primary;
$subtitle-weight: 300;
$body-family: $family-primary;
$body-color: $grey-black;

// Navbar
$navbar-height: 4.25rem;
$navbar-padding-vertical: 0;
$navbar-box-shadow-size: 0 0 6px;
$navbar-box-shadow-color: $grey;
$navbar-item-color: $grey-black;
$navbar-item-hover-color: $primary-light;
$navbar-item-active-color: $primary-light;
$navbar-item-hover-background-color: transparent;
$navbar-item-active-background-color: transparent;
$navbar-bottom-box-shadow-size: 0;
$navbar-dropdown-boxed-radius: 0px;
$navbar-dropdown-border-top: 0 solid transparent;
$navbar-divider-height: 0;
$navbar-dropdown-offset: 0px;
$navbar-dropdown-background-color: $primary-dark;
$navbar-dropdown-item-color: $white;
$navbar-dropdown-item-hover-color: $white;
$navbar-dropdown-item-active-color: $white;
$navbar-dropdown-item-hover-background-color: transparent;
$navbar-dropdown-item-active-background-color: transparent;

//cards
$card-radius: 0.6rem;

//notifications
$notification-background-color: $black;
$notification-radius: 0;
// Footer
$footer-background-color: $blue;
$footer-color: $white;
// Menu
$menu-item-color: $white;
$menu-item-hover-color: $primary-light;
$menu-item-active-color: $primary-light;
$menu-list-link-padding: 0.5em 0;
$menu-item-hover-background-color: transparent;
@function diffuse-shadows( $size, $color: rgba(0, 0, 0, 0.125), $multiply: false) {
    // via https://codepen.io/marksdk/pen/wvBoNaj
    $val: 0 1px 1px $color;
    @for $i from 1 through $size {
        @if $i % 2==0 {
            @if $multiply==true {
                $i: $i * 2;
            }
            $val: #{$val},
            0 #{$i}px #{$i}px #{$color};
        }
    }
    @return $val;
}

@mixin diffuse($size, $color, $multiply) {
    box-shadow: diffuse-shadows($size, $color, $multiply);
}

@mixin diffuse-text($size, $color, $multiply) {
    text-shadow: diffuse-shadows($size, $color, $multiply);
}

// Computed Hero colors
$custom-blue: (
    "blue":($blue, $white),
    "blue-invert":($white, $blue)
);

$custom-colors: $custom-blue;

// Acordeón
$tabs-border-bottom-width: 0;
$tabs-link-color: $white;
$tabs-border-bottom-color: hsla(223, 19%, 61%, 1);
$tabs-link-active-color: $white;
$tabs-link-active-border-bottom-color: #2772b1;
$tabs-link-hover-color: $white;
$tabs-link-hover-border-bottom-color: $primary-dark;
$tabs-link-active-border-bottom-color: $blue-light;

// Animated buttons
$blue-animation-border: url(../img/sprite-blue.png);
$white-animation-border: url(../img/sprite-white.png);
$grey-animation-border: url(../img/sprite-grey.png);

$tabs-link-active-border-bottom-color: $blue-light;
