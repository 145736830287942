/* Estos estilos aplican a todos los slider*/
.block-slider {
    .slider-content {
        margin: 0 auto;
        width: 100%;
        position: relative;

        .glide__slides {

            .glide__slide {
                position: relative;

                /* Contenedores de texto */
                .content-image {
                    $space-x: 1.5rem;
                    box-sizing: border-box;
                    display: flex;
                    flex-flow: column;
                    position: absolute;
                    z-index: 2;
                    left: $space-x;
                    right: $space-x;
                    bottom: 0;

                    a:hover {
                        color: $blue;
                    }

                    @include desktop {
                        right: 0;
                        left: 0;
                    }
                }
            }
        }
    }
}


/* Slider que no pertece a Home */
body.product .slider-secondary,
body.service .slider-secondary {
    margin-block: $gap * 2;
    h3 {
        color: $black;
    }
}

.slider-secondary {
    h3 {
        font-size: 32px;
        font-weight: 500;
        color: $blue;
        @include tablet {
            font-size: 42px;
        }
    }

    h4 {
        font-size: 24px;
        font-weight: 500;
        color: $grey-dark;
        line-height: 1.5;
        @include tablet {
            font-size: 30px;
        }
    }

    p {
        font-size: 16px;
        margin-top: 1rem;
        @include tablet {
            font-size: 24px;
        }
    }

    a {
        font-size: $size-6;
        background-color: $black;
        color: $white  !important;
        cursor: pointer;
        padding-bottom: calc(0.5em - 1px);
        padding-top: calc(0.5em - 1px);
        text-align: center;
        white-space: nowrap;
        border-radius: 9999px;
        padding-left: calc(1em + 0.25em);
        padding-right: calc(1em + 0.25em);
        margin-block: 1rem;
        display: inline-block;
        @extend .btn-hover;
    }

    /****************************/
    /*         Animación        */
    /****************************/


    .glide__slide--active .slider-text h3 {
        /* duration | timing-function | delay | iteration-count | direction | fill-mode | play-state | name */
        animation: 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0s 1 normal both running fader-text;
    }
    .glide__slide--active .slider-text h4 {
        /* duration | timing-function | delay | iteration-count | direction | fill-mode | play-state | name */
        animation: 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.2s 1 normal both running fader-text;
    }
    .glide__slide--active .slider-text p {
        /* duration | timing-function | delay | iteration-count | direction | fill-mode | play-state | name */
        animation: 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s 1 normal both running fader-text;
        
    }
    .glide__slide--active img,
    .glide__slide--active video {
        /* duration | timing-function | delay | iteration-count | direction | fill-mode | play-state | name */
        animation: 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0s 1 normal both running fader-img;
        animation: fader-img 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    /****************************/
    /*      Fin Animación       */
    /****************************/

    .slider-text {
        
        position: absolute;
        top: 324px;
        right: 0;
        left: 0;
        padding: 1.5rem 2rem;
        width: 100%;
        height: 100%;
        @include tablet {
            top: 480px;
        }
        @include desktop {
            width: 43%;
            top: 0;
            left: auto;
        }
        h3, h4, p {
            opacity: 0;
        }
    }

    .glide__slides {
        &.half-height {
            height: 650px;

            @include tablet {
                height: 800px;
            }

            @include desktop {
                height: 590px;
            }
        }
    }

    .image-halfheight {
        display: inline;

        img {
            opacity: 0;
            height: 324px;
            width: 100%;
            object-fit: cover;
            border-bottom-right-radius: 50px;
            @include tablet {
                height: 480px;
            }
            @include desktop {
                height: 100%;
                width: 57%;
            }
        }
    }

    video {
        height: 324px;
        width: 100%;
        object-fit: cover;
        border-bottom-right-radius: 50px;
        @include tablet {
            height: 480px;
        }
        @include desktop {
            height: 100%;
            width: 57%;
        }
    }

    .glide__arrow {
        top: 2rem;
        transform: none;

        &.btn.btn--size {
            padding: 10px 11px !important;
        }
        
        &.glide__arrow.glide__arrow--left {
            left: auto;
            right: 4.5rem;

            @include desktop {
                left: 45%;
                right: auto;
            }
        }

        &.glide__arrow.glide__arrow--right {
            left: auto;
            right: 1rem;

            @include desktop {
                left: 50%;
                right: auto;
            }
        }
    }

    .slide-overlay {
        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 324px;
            background: linear-gradient(to top, transparent, rgba(14, 13, 19, 0.73));
            mix-blend-mode: normal;
            border-bottom-right-radius: 50px;

            @include tablet {
                height: 480px;
            }

            @include desktop {
                height: 100%;
                width: 57%;
            }
        }
    }
}

.home {
    /* Estilos de los slider de tamaño grande de home.
    Template home_slider.html */
    .block-slider {
        .glide__slides {
            height: 909px;
            .bg-image {
                height: 909px;
            }
            .image {
                height: 100%;
            }
            img {
                height: 100%;
                object-fit: cover;
            }
        }
        #is-header-hero video {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }

        .content-image {
            height: 909px;
            justify-content: center;
            top: 0;
            align-items: center;
            @include tablet-only {
            div.rich-text {
                width: 75%;
            }
        }
        }

        .rich-text {

            h3 {
                color: $white;
                line-height: 3.5rem;
                font-size: 2.5rem;
            }

            h4 {
                color: $white;
                line-height: 2.75rem;
                font-size: 1.75rem;
            }


            a {
                @extend .short-deco;
                color: $white;
            }
        }
    }
}


/*Estos estilos están afectando tanto a block-slider como a block-product */
.glide__arrow {
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: none;

    &.arrow--start {
        top: -1rem;
    }

    &.arrow--end {
        @include mobile {
            top: 80%;
        }
    }

    .icon {
        opacity: .5;
    }

    &.glide__arrow--left {
        left: $gap / 2;

        @include widescreen {
            left: 4vw;
        }
    }
    &.glide__arrow--right {
        right: $gap / 2;

        @include widescreen {
            right: 4vw;
        }
    }
}


.glide__bullets {
    max-width: 50%;
    bottom: $gap;

    .glide__bullet {
        border-radius: 5px;
        width: 12vw;
        height: 3px;
        margin: 0 $gap / 4;
        background-color: $grey-lighter;
        box-shadow: none;
        &.glide__bullet--active {
            background-color: $primary-light;
            @include transform(scale, $scale-105, $duration-0, $default);
        }
        &:hover,
        &:focus {
            border: transparent;
        }
    }
    &.bullets-outside {
        bottom: -$gap;
    }
}