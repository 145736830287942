.block-contact {
    .columns {
        @extend .is-variable;
        @extend .is-5;
        margin: 0;
    }

    .column {
        @include mobile {
            padding-left: 0 !important;
        }
    }

    .column:nth-child(odd) {
        @include tablet {
        padding-left: 0;
        }
    }

    ul {
        display: flex;
        justify-content: space-between;
        list-style: none;
    }

    .card-contact {
        .icon {
        border-radius: 50%;
        width: $gap;
        height: $gap;
        }
        .contact-value{
            padding-inline: .5rem;
        }
    }
    
    svg.svg-inline--fa {
        width: 20px;
        height: 20px;
    }

    .social-links {
        flex-direction: row;
        margin: 0;

        li {
            margin: 0;
            margin-right: $gap / 2;
        }
    }

    .is-cursor-default {
        cursor: default;
    }

    .deco-border {
        display: flex;
        align-items: center;
        color: $primary;

        &:hover {
            color: $primary;
        }
    }
}