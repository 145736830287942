.block-paragraph {
    @extend .content;
    @extend .is-medium;
    
  p:empty {
    display: none;
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

    a {
      @extend .short-deco;

      &:hover {
        color: $blue;
     }
  }
        /* margin: 16px 0;
        font-weight: 600;
        $decoration-bottom: -3px;
        position: relative;

        &::after {
            content: "";
            width: 0;
            height: 2px;
            position: absolute;
            bottom: $decoration-bottom;
            left: 0;
            background-color: $blue;
            transition: all 0.2s ease-in-out;
        }

        @media (hover: hover) {
            &:hover {
                color: $blue;
            }
            &:hover::after {
                width: 100%;
                color: $blue;
            }
        } */
   

    .richtext-image {
        height: auto;
        display: block;

        @include tablet {
            width: 50%;
            max-width: 50%;
        }

        &.left {
            float: left;

            @include tablet {
                padding: 0.3rem 1rem 0 0;
            }
            @include desktop {
                padding: 0.3rem 2rem 0 0;
            }
        }

        &.right {
            float: right;

      @include tablet {
        padding: 0.3rem 0 0 1rem;
      }

      @include desktop {
        padding: 0.3rem 0 0 2rem;
      }
    }

    &.center {
      max-width: 70%;
      padding: 1rem 0;
      margin: auto;
    }

    &.full-width {
      padding: 1rem 0;
      max-width: 100%;
      width: fit-content;
      width: -moz-available;
      width: -webkit-fill-available;
    }
  }

  h3,
  h4 {
    color: $link;
    font-weight: 400;

    b, strong {
      /* Un default de SASS estaba pisando el color azul con strong */
      color: $link;
      font-weight: 600;
    }
  }
}