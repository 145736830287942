.shortcuts {
    .column {
        .image {
            align-items: center;
            background-color: #e8e8e8;
            display: flex;
            justify-content: center;
            height: 4.25rem;

            @include desktop {
                height: 8.5rem;
            }

            .image-inner {
                background-size: contain;
                max-height: 2.9375rem;
                max-width: 2.9375rem;
                height: 100%;
                width: 100%;

                @include desktop {
                    max-height: 5.875rem;
                    max-width: 5.875rem;
                }
            }
        }

        a {
            align-items: center;
            background-color: #f4f4f4;
            color: $grey-dark;
            display: flex;
            font-size: 1.1rem;
            text-align: center;
            line-height: 1;
            justify-content: center;
            height: 3.25rem;
        }
    }

    .short-link {
        display: flex;
        flex-flow: column;
        justify-content: space-around;

        .image {
            display: none;
        }

        a {
            background-color: $grey;
            color: $white;
            font-size: 1rem;
            margin-bottom: 3%;
            min-height: 1.125rem;
            text-transform: uppercase;
            width: 100%;
            height: 30%;

            @include desktop {
                min-height: 2.25rem;
            }

            &:hover {
                background: $green;
                color: $grey-light;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
