.results {
    background-color: $grey-lighter;

    #is-header-hero {
        background-image: url(../img/bg-listings-mobile.jpg);
        
        @include tablet {
            background-image: url(../img/bg-listings-tablet.jpg);
        }

        @include desktop {
            background-image: url(../img/bg-listings-desktop.jpg);
        }
    }

    .section {
        .mt-7 {
            margin-top: $gap * 2 !important;
        }
        .card {
            height: 100%;
            border-radius: $card-radius;
            box-shadow: none;
            transition: box-shadow ease-in .3s;
            img {
                border-top-left-radius: $card-radius;
                border-top-right-radius: $card-radius;
            }
            @media (hover: hover) {
                &:hover {
                    transition: box-shadow ease-out .3s;
                    @include diffuse(8, rgba($grey-black, 0.12), true);
                }
            }
        }
    }
}
