#navbar {
    position: absolute;
    width: 100%;
    transition: ease $duration-300;

    @include fullhd {
        min-height: 5.25rem;
    }

    &.is-fixed-top {
        position: fixed;
    }


    .navbar-end {
        @include desktop {
            flex-direction: row-reverse;
        }

        [class*="navbar-end-"] {
            @include desktop {
                align-items: center;
                display: flex;
                height: 100%;
            }
        }

        .navbar-end-first {

            @include fullhd {
                margin-left: $gap;
            }
        }
    }

    #navbar-main {
        &:after {
            @include touch {
                background-color: $white;
                content: "";
                position: fixed;
                height: 100vh;
                width: 100%;
                z-index: 0;
            }
        }
    }
}

.has-navbar-transparent {
    &.has-navbar-white {
        .navbar {
            background-color: rgba($white, 0.95);
            &:hover {
                background-color: $white !important;
            }
            &.has-shadow {
                box-shadow: 0 0 6px $grey;
            }
        }
    }

    .navbar {
        background-color: rgba($primary-dark, 0);
        background-blend-mode: darken;

        &.has-shadow {
            box-shadow: none;
        }
    }
}

.is-context-menu {
    #navbar {
        background-color: $white;
    }
}
