[class*="block-"] {
  margin-block: $gap * 2;
  @extend .container;
  @extend .is-max-desktop;

    @include touch {
      margin-inline: $gap / 2;
    }
}

[class*="block-"]:first-child {
  margin-top: 0;
}

[class*="block-"]:last-child {
  margin-bottom: $gap * 6;
}

/* Bloques de ancho completo*/
.block-services,
.block-vertical_tabs,
.block-accordion,
.block-slider,
.block-related_news,
.block-products,
.block-news,
.block-authorities,
.block-banner.box-banner2,
.block-banner.box-banner3 {
  margin: 0;
  width: 100%;
  max-width: none !important;

  &:last-child {
    margin: 0;
  }
}

.block-organization_chart {
  @include mobile {
    margin-inline: 0;
  }
  max-width: none !important;
}

/* Bloque que está adentro de otro*/
[class*="block-"] {
  .block-column {
    margin: 0;
  }
}
/*Bloque con espaciado especial */
.block-document {
  margin-block: $gap / 2;
}

/* Hace que block-title tenga la mitad de margen hacia abajo
y el bloque que le sigue la mitad de margen hacia arriba */
.block-title:not(:last-child) {
  margin-bottom: $gap / 2;
}
.block-title + [class*="block-"]  {
  margin-top: $gap / 2;
}

/*Sobreescribe default de Bulma para que tome el mismo espaciado general*/
.block-paragraph:not(:last-child) {
  margin-bottom: $gap * 2;
}

/* Estilos específicos de espaciado para new*/
.new {
  [class*="block-"]:last-child {
    margin-bottom: $gap * 4;
  }
  .block-news {
    margin: 0 !important;
  }
}