// animations for the collapsible Arrows
$rotate-0: 0deg;
$rotate-45: 45deg;
$rotate-180: 180deg;

// Duración en milisegundos
$duration-0: 0s;
$duration-100: 100ms;
$duration-200: 200ms;
$duration-300: 300ms;
$duration-400: 400ms;
$duration-500: 500ms;
$duration-600: 600ms;

// Animation timing function
$default: linear;

// Transform scale
$scale-0: 0;
$scale-50: 0.5;
$scale-70: 0.7;
$scale-80: 0.8;
$scale-90: 0.9;
$scale-95: 0.95;
$scale-100: 1;
$scale-105: 1.05;
$scale-110: 1.1;
$scale-125: 1.25;
$scale-140: 1.4;
$scale-150: 1.5;


@mixin transform($property, $value, $duration, $function) {
  transform: #{$property + "(" + $value + ")"} ;
  transition: transform $duration $function;
}

@keyframes fadeIn {
  from   {
      opacity: 0;
      transform: translateY(0) scale($scale-95);
  }
  to  {
      opacity: 1;
      transform: translateY(0) scale($scale-100);
  }
}

@keyframes zoomIn {
  from   {
      opacity: 0.5;
      transform: translateY(0) scale(1.95);
  }
  to  {
      opacity: 1;
      transform: translateY(0) scale(2.2);
  }
}

@keyframes slidein {
  from {
    display: none;
    opacity: 0;
  }

  to {
    display: flex;
    opacity: 1;
  }
}

@keyframes slideout {
  0% {
    display: flex;
    opacity: 1;
  }

  90% {
    opacity: 0.1;
  }

  100% {
    display: none;
    opacity: 0;
  }
}

// Animación usada en títulos
@keyframes slicetop {
  0% {
    opacity: 0;
    margin-top: 200px;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

// Animación usada en subtítulos
@keyframes opacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

//Animaciones de 0 a mira y viceversa
@keyframes TargetIn {
  0% {
      height: 0;
      width: 0;
  }
  100% {
      height: 10px;
      width: 10px;
  }
}
@keyframes TargetOut {
  0% {
      height: 10px;
      width: 10px;
  }
  100% {
      height: 0;
      width: 0;
  }
}

//Animaciones de mira a borde completo y viceversa
@keyframes SightIn {
  0% {
      height: 10px;
      width: 10px;
  }
  100% {
      height: 50%;
      width: 50%;
  }
}
@keyframes SightOut {
  0% {
      height: 50%;
      width: 50%;
  }
  100% {
      height: 10px;
      width: 10px;
  }
}

// Transparencia
.overlay {
  background-color: rgba(0,51,98,1);
  &::before {
    @extend %overlay;
    content: "";
    // background-color: rgba($primary-dark, 0.75);
    // background: radial-gradient(ellipse at top, #e66465, transparent),
    //             radial-gradient(ellipse at bottom, #4d9f0c, transparent);
    background: radial-gradient(90.52% 145.3% at 44.96% 117.56%, rgba(120, 198, 232, 0) 0%, rgba(1, 73, 144, 0.75) 75.14%);
    mix-blend-mode: multiply;
  }
  &::after{
      content: ""; // ::before and ::after both require content
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(94.12% 210.45% at 67.89% 192.47%, rgba(1, 73, 144, 0) 14.33%, rgba(1, 73, 144, 0.81) 100%);
      mix-blend-mode: normal;
  }
}

.overlay-linear-blue {
  $bg-color-light: rgba($primary, 0.4);
  $bg-color-dark: rgba($primary, 1);
  &::after {
    @extend %overlay;
    content: "";
    background-color: $bg-color-light;
    background: linear-gradient(to top, $bg-color-light, $bg-color-dark);
  }
}

.overlay-radial-blue {
  $bg-color-light: rgba($primary, 0.6);
  $bg-color-dark: rgba($primary, 1);
  &::after {
    @extend %overlay;
    content: "";
    background-color: $bg-color-light;
    background: radial-gradient(circle at bottom center, transparent, $bg-color-light, $bg-color-dark)
  }
}

// Buttons

.btn-hover {
  @media (hover: hover) {
    &:hover {
      box-shadow: rgba($grey-black, 0.3) 0 4px 8px, rgba($grey-black, 0.2) 0 7px 13px -3px;
      transform: translateY(-2px);
      transition: transform 0.15s ease-in;
    }
  }
}

%hover-icon-button {
  border-radius: 50%;
  background-color: rgba($white, 0.5);
  @include diffuse(2, rgba($grey-black, 0.2), false);
}
// Plus buttons
.btn-plus {
  span {
    border-radius: 50%;
    background-color: transparent;
  }

  &:hover span {
    @extend %hover-icon-button;
  }
}

// Arrow buttons
.btn {
  $icon-size: 16px;
  $opacity-100: 1;
  $opacity-80: 0.7;
  $opacity-50: 0.5;
  cursor: pointer;
  border-radius: 50%;

  &.btn--size {
    padding: ($icon-size - 9) ($icon-size - 8);
  }
  &.btn--size-modal {
    padding: ($icon-size - 2) ($icon-size + 2);
  }

  &.btn--grey-dark {
    border: 1px solid $white;
    .icon {
      color: $white;
    }
  }

  &.btn--blue {
    border: 1px solid $primary;
    .icon {
      color: $primary;
    }
  }

  .icon {
      font-size: $icon-size;
  }

  &:disabled {
    opacity: $opacity-50;
    .icon {
        opacity: $opacity-50;
    }
  }

  &:hover {
    @extend %hover-icon-button;
}
}

// Icons
.icon {
  $icon-large-x: 28px;
  $icon-large-y: 28px;
  $icon-small-x: 22px;
  $icon-small-y: 22px;

  &.is-small {
    $icon-padding-x: 20px;
    $icon-padding-y: 20px;
  }


  &.is-rounded {
    border: 1px solid $white;
    border-radius: 50%;
  }

  &.is-large {
    padding: $icon-large-x $icon-large-y;
  }

  &.is-small {
    padding: $icon-small-x  $icon-small-y;
  }

}

@mixin container_is_fluid {
  @include mobile {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
  }
}

.container-mobile {
  @include container_is_fluid;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes fader-text {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  33% {
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes fader-img {
  0% {
      opacity: 0.3;
  }
  100% {
      opacity: 1;
  }
}

.overlay-link:hover {
  cursor: url('../img/circle.svg'), auto;
}