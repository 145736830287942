table {
    caption-side: bottom;

    tbody {
        tr:nth-child(odd) {
            background-color: transparent;
        }
        tr:hover {
            background-color: transparent;
        }
    }
}

.tab-container {
    .block-table{
        .section {
            padding: 3rem 0;
            overflow: hidden;
        }
    }
}