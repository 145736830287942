.block-banner {
    position: relative;
    .image {
        height: 100%;
        width: 100%;
        background-color: $primary;
    }

    .banner {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .banner-content {
        display: block;
        color: $white;

        h3 {
            color: $white;
            line-height: 3.5rem;
            font-size: 2.5rem;
        }

        h4 {
            color: $white;
            line-height: 2.75rem;
            font-size: 1.75rem;
        }

        a {
            @extend .short-deco;
            color: $white;
        }

        /* a {
            @extend .button;
            @extend .is-rounded;
            @extend .btn-hover;
            font-weight: 600;
        } */
    }
    /* Banners en caja. Se ajustan al contenido y tienen
    margen superior e inferior*/
    &.box-banner1 {
        .show-img {
            @include tablet {
            .image {
                position: absolute;

                img {
                    height: 100%;
                }
            }
        }

        .banner {
            @include tablet {
                min-height: 252px;
            }
        }
    }

    .decorative-img {
        .banner {
            min-height: 190px;
            @include tablet {
                min-height: 252px;
            }
        }
        .image {
            position: absolute;
            img {
                height: 100%;
            }
        }
    }
    }

    /* Banners de ancho completo. Tienen el mismo alto
    que los banner en caja pero las reglas de ancho completo*/
    &.box-banner2 {
        .image {
                position: absolute;
                img {
                    height: 100%;
                }
            }

        .banner-container {
            min-width: 100%;
            min-width: -moz-available;
            min-width: -webkit-fill-available;
            min-width: fill-available;

            .banner {
                min-height: 190px;
                @include tablet {
                min-height: 252px;
            }
            }
        }
    }
    /* Banners grandes. Tienen el doble de alto y
    las reglas de ancho completo*/
    &.box-banner3 {
        .image {
            position: absolute;
            img {
                height: 100%;
            }
        }

        .banner-container {
            min-width: 100%;
            min-width: -moz-available;
            min-width: -webkit-fill-available;
            min-width: fill-available;

            .banner {
                min-height: 378px;
                @include tablet {
                min-height: 504px;
                }
            }
        }
    }
}