.gallery {

    .glide__slides{
        overflow: visible;
    }
    .glide__slide {
        position: relative;

        &.glide__slide--active {
            z-index: 3;

            figcaption {
                display: block;
                font-size: 16px;
                font-weight: 300;
                text-align: start;
                color: $black;
                padding-top: 0.75rem;
                min-height: 4rem;
            }
        }

        .image {
            position: relative;
            img {
                object-fit: cover;
            }
            &:hover {
                cursor: url('../img/circle.svg'), auto;
            }
        }
        
        
    }

    .glide__track {
        padding: 0;
        overflow-x: hidden;
    }

    .glide__arrow {
        background-color: transparent;
        border: none;
        border-radius: 0;
        top: 45%;
        height: 90%;
        width: 5rem;

        &.glide__arrow--left {
            left: 0;
            &::before {
                content: url('../img/arrow-left.svg');
                left: 0;
                right: 0;
                text-align: center;
                display: inline-block;
                top: 50%;
                position: absolute;
                @include mobile {
                    content: url('../img/arrow-left-mobile.svg');
                    top: 35%;
                }

            }
        }
        &.glide__arrow--right {
            right: 0;
            &::before {
                content: url('../img/arrow-right.svg');
                left: 0;
                right: 0;
                text-align: center;
                display: inline-block;
                top: 50%;
                position: absolute;
                
                @include mobile {
                    content: url('../img/arrow-right-mobile.svg');
                    top: 35%;
                }
            }
        }
    }

    


    .btn-plus:hover span, .glide__arrow:hover {
        box-shadow: none;
    }
}




.modal.is-active {
    .modal-button {
        position: fixed;
        top: 20px;
        left: 20px;
        border-radius: 40px;
        height: 32px;
        max-height: 32px;
        max-width: 32px;
        min-height: 32px;
        min-width: 32px;
        width: 32px;
        @include animated_target($white-animation-border);
    }

    .close-deco {
        position: fixed;
        top: 32px;
        right: 32px;
        border-radius: 40px;
        height: 32px;
        max-height: 32px;
        max-width: 32px;
        min-height: 32px;
        min-width: 32px;
        width: 32px;
        @include animated_target($white-animation-border);

        .btn-border {
            top: -12px;
            right: 16px;
            background: rgba(51, 51, 51, 0.3);
        }

        .close-button {
            background-color: transparent;
            top: 32px;
            right: 32px;
            &::before {
                z-index: 1;
            }
            &:hover {
                background-color: transparent;
            }
        }
    }
}

.glide__download {
    background: rgba(51, 51, 51, 0.3);
}